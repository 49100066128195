.ionContent {
  --ion-background-color: var(--weaver-light-grey);
}

.label {
  font-size: 1.125rem;
  margin-top: 0.5rem;

  ion-badge {
    max-height: 1.5rem;
    max-width: 1.5rem;
    font-size: 1rem;
    margin-bottom: -0.13rem;
  }
}

.ctaButtonsContainer {
  margin: 0 0.5rem;

  ion-button {
    width: 100%;

    span {
      margin-right: auto;
      display: inline-flex;
    }
  }
}

.noResultsContainer {
  margin: 1rem 0;

  ion-label {
    font-weight: 600;
    padding-inline-start: 1.5rem;
  }

  & + & {
    margin-top: 10rem;
  }
}

.card {
  --background: var(--theme-white);

  border-radius: 1em;

  --color: var(--ion-text-color);

  max-width: 40rem;

  p {
    font-size: 0.9rem;
  }
}

.leadCard {
  margin-bottom: 1rem;
  height: 9.375rem;
}

.projectsIcon {
  width: 3rem;
}

.leadHeader {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: large;
  font-weight: 700;
  padding: 0.5rem;
}

.leadSubtitleContainer {
  height: 5rem;

  .leadSubtitle {
    height: 3rem;
    padding: 0.5rem;
    padding-top: 0;
    /* stylelint-disable-next-line value-no-vendor-prefix -- text overflow ellipsis apply on line n, in our case 3 */
    display: -webkit-box;
    max-width: 95%;
    white-space: wrap;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
  }
}

.leadsContainer {
  display: flex;

  ion-thumbnail {
    min-width: 9.35rem;
    min-height: 9.35rem;
  }

  ion-grid {
    max-width: calc(100% - 9.5em);
    padding: 0;
  }

  ion-button {
    padding: 0;
    margin: 0;
  }

  .leadCopyContainer {
    flex: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    padding: 0.5rem 1px 0.5rem 0.75rem;
    border-top: 0.1rem solid;
    border-top-color: var(--ion-color-light);

    .date {
      color: var(--ion-color-primary);
    }

    .divider {
      color: var(--ion-color-light-shade);
      margin-left: 0.4rem;
      margin-right: 0.4rem;
    }
  }
}

.arrowIcon {
  ion-icon {
    margin-bottom: -0.78rem;
    padding-right: 1.25rem;
    padding-top: 0.5em;
    color: var(--ion-color-primary);
    align-items: center;
    position: relative;
    border-top: 0.1rem solid;
    border-top-color: var(--ion-color-light);
  }
}

.leadStaticGoogleMapContainer {
  flex-direction: column;
}

.projectSubtitle {
  padding: 0.5rem;
  padding-top: 0;
}

.projectHeader {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: large;
  font-weight: 700;
  padding: 0.5rem;
}

.projectStaticGoogleMapContainer {
  max-height: 140rem;
  max-width: 140rem;
  object-fit: none;
}

.projectContainer {
  ion-icon {
    margin-bottom: -0.78rem;
    padding-right: 1.25rem;
    padding-top: 0.5em;
    border-top: 0.1rem solid;
    border-top-color: var(--ion-color-light);
  }

  .projectCopyContainer {
    flex: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 1px 0.5rem 0.75rem;
    border-top: 0.1rem solid;
    border-top-color: var(--ion-color-light);

    .projectStatusText {
      color: var(--ion-color-primary);
    }

    .projectDivider {
      color: var(--ion-color-light-shade);
      margin-left: 0.4rem;
      margin-right: 0.4rem;
    }
  }
}

/** @deprecated
'MW-2422-tasks-qol1-part3'
*/

.projectTitleContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.projectTitleContainerV2 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  ion-button {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.projectCard {
  margin-bottom: 1rem;
}

/** @deprecated
'MW-2422-tasks-qol1-part3'
*/
.nextTaskButton {
  padding-left: 0.5rem;
  margin: 0;

  &.extraWidth {
    max-width: 18rem;
  }
}

.nextTaskButtonV2 {
  padding-left: 0.5rem;
  margin: 0;

  &.extraWidth {
    text-overflow: ellipsis;
    max-width: 30rem;
  }
}
